<template>
	<div>
		<screen class="api-view" size="large" style="position: relative">
			<div :style="{ position: 'absolute', top: '-7px', right: '10px' }">
				<ui-button @click="openInviteModal"
					>Invite a co-worker</ui-button
				>
			</div>
			<h1 slot="title">API Keys</h1>
			<div slot="body">
				<div slot="body">
					<div>
						<div
							class="roundup-alert roundup-alert-info"
							style="margin-top: 20px"
						>
							Public Key
						</div>
						<div
							class="roundup-alert roundup-alert-boring"
							style=""
						>
							{{ merchant.public_key }}
						</div>
					</div>
					<api-token
						label="Production"
						v-bind:apiKey="merchant.api_key"
					/>
					<api-token
						label="Development"
						v-bind:apiKey="merchant.dev_api_key"
					/>
				</div>
			</div>
			<modal v-model="inviteModal">
				<user-edit-view></user-edit-view>
			</modal>
		</screen>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { mapState, mapActions } from 'vuex'
import { getUrlParams } from '../../../utilities'
import Screen from '../../ui/Screen'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'
import Modal from '../../ui/Modal'
import ApiToken from '../../ui/APIToken'
import Card from '../../ui/Card'
import UiButton from '../../ui/Button'
import UserEditView from '../Users/UserEditView'

export default {
	name: 'api-view',
	components: {
		Screen,
		UiButton,
		UiLabel,
		UserEditView,
		Datepicker,
		Card,
		UiInput,
		Modal,
		ApiToken
	},
	data() {
		return {
			inviteModal: false,
			showProd: false,
			showDev: false,
			isOnboarding: false
		}
	},
	computed: {
		...mapState({
			merchant: ({ merchant }) => merchant.item
		}),
		hideKey(key) {
			if (!this.merchant && !key) {
				return '****'
			}
			const arr = this.merchant
				? this.merchant.api_key.split('').map(x => '• ')
				: []
			return arr.join().replace(/,/g, '')
		}
	},
	methods: {
		...mapActions(['addPlaid', 'rotateToken', 'getMerchantDetails']),
		openInviteModal() {
			this.inviteModal = true
		},

		show(type) {
			if (type === 'prod') {
				this.showProd = !this.showProd
			}
			if (type === 'dev') {
				this.showDev = !this.showDev
			}
		}
	},
	mounted() {
		this.getMerchantDetails()

		const params = getUrlParams(window.location.href)
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.body {
	background: $roundup-cerulean;
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 50%;
	max-width: 600px;
	margin: auto;
	text-align: center;
	.plaid-link-button {
		background: transparent;
		color: $roundup-navy;
	}
	p {
		color: $white;
	}
	.link {
		cursor: pointer;
		text-align: center;
		margin-bottom: 0;
		margin-top: 0;
		&:hover {
			text-decoration: underline;
		}
	}
	.security {
		font-size: 12px;
		color: $white;
		text-align: center;
		padding: 20px 10px;
		max-width: 320px;
		margin: 0 auto;
		opacity: 0.7;
	}
	h1 {
		font-family: 'Bitter', serif;
		font-weight: bold;
		font-size: 20px;
	}
	img {
		height: 80px;
	}
}
.button {
	&:hover {
		&:enabled {
			transform: translateY(-2px);
			&::after {
				opacity: 1;
			}
		}
	}
}
</style>
