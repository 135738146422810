<template>
	<div>
		<div class="roundup-alert roundup-alert-info" style="margin-top: 20px">
			{{ label }}
		</div>
		<div
			style="min-width: 397px"
			class="roundup-alert roundup-alert-boring"
			v-if="!show"
		>
			{{ hideKey }}
		</div>
		<div
			style="min-width: 398px"
			class="roundup-alert roundup-alert-boring"
			v-else
		>
			{{ apiKey }}
		</div>
		<ui-button class="roundup-button-m append" @click="toggleShow">{{
			show ? 'Hide' : 'View'
		}}</ui-button>
		<i
			@click="showRotate"
			class="fas fa-sync-alt"
			style="margin-left: 10px"
		></i>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import UiButton from './Button'

export default {
	name: 'api-token',
	components: { UiButton },
	props: ['apiKey', 'label'],
	data() {
		return {
			show: false
		}
	},
	computed: {
		hideKey() {
			if (!this.apiKey) {
				return '****'
			}
			const arr = this.apiKey.split('').map(x => '• ')
			return arr.join().replace(/,/g, '')
		}
	},
	methods: {
		...mapActions(['rotateToken']),
		toggleShow() {
			this.show = !this.show
		},
		showRotate() {
			this.$swal({
				title: 'Confirm Rotation',
				text: 'It will immediately invalidate your existing API key.',
				showCancelButton: true,
				allowOutsideClick: false,
				allowEscapeKey: false
			}).then(result => {
				if (result.value) {
					this.rotateToken(this.type)
				}
			})
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
</style>
